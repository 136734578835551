<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import WebFontLoader from 'webfontloader';

export default {
  created() {
    WebFontLoader.load({
      // custom: {
      //   Use FVD notation to include families https://github.com/typekit/fvd
      //   families: ['Rubik:n4,n5,n7'],
      //   Path to stylesheet that defines font-face
      //   urls: ['/static/assets/fonts/stylesheet.css'],
      // },
      google:{
        families: ['Work+Sans']
      },
      active: this.setFontLoaded,
    });
  },
  methods: {
    setFontLoaded() {
      this.$emit('font-loaded');
    },
  },
};
</script>

<style>
#app {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
