<template>
  <div>
    <!-- navigation  -->
    <div
      class="fixed top-0 left-0 z-10 w-full py-4 transition duration-200 ease-linear bg-transparent"
      :class="
        fixtop
          ? 'bg-white shadow-md z-20'
          : 'bg-gradient-to-b from-gray-900'
      "
    >
      <div class="container">
        <div class="flex items-center justify-between w-full">
          <img src="/images/main-logo.png" alt="" class="w-32" />
          <div class="md:hidden">
            <p
              class="text-lg font-semibold leading-tight text-right"
              :class="fixtop ? 'text-gray-1' : 'text-yellow-1'"
            >
              Call Us:<br />
              <a href="tel:+60102111182"> 010-211 1182</a>
            </p>
          </div>
          <div
            class="hidden text-xl font-semibold text-right md:flex md:items-center"
          >
            <img src="/images/call-1.png" alt="" class="w-12" />
            <p class="pl-2 leading-tight text-gray-1" :class="fixtop ? 'text-gray-1' : 'text-yellow-1'">
              Call Us:<br />
              <a href="tel:+60102111182">010-211 1182</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style>
.nav-menu {
  padding-top: 10px;
  position: absolute;
  width: 100%;
}
.open-menu {
  opacity: 1;
  height: 150px;
}
.closed-menu {
  opacity: 0;
  height: 0;
  padding: 0;
}
</style>
