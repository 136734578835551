<template>
  <div class="bg-center bg-no-repeat bg-cover bg-custom" style="background-image: url('/images/bg-banner.jpg')">
    <div class="container">
      <div class="relative py-40 md:py-48">
        <div class="md:w-2/3 lg:w-1/2">
          <div class="p-6 text-white md:py-10 bg-gray-1 bg-opacity-90">
            <h1 class="text-3xl font-bold leading-none md:text-4xl">
              No.1 <br class="hidden xl:block" />
              <span class="text-yellow-1">Award Winning Flooring Specialist </span>in Malaysia
            </h1>
            <div class="bg-white w-14 h-0.5 mt-4"></div>
            <p class="mt-4 leading-tight md:text-base">
              Over 15 years of experience in Flooring field
            </p>

            <!-- cta-button -->
            <div v-if="!hideEnquiry" class="mt-4">
              <p tabindex="0"
                class="py-1 text-center text-gray-900 transition duration-200 ease-linear transform rounded-md hover:scale-110 hover:bg-yellow-500 w-44 bg-yellow-1">
                <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }">Get A Free Quote</a>
              </p>
            </div>
          </div>
        </div>
        <div class="absolute w-full h-auto md:w-[25rem] md:right-3 bottom-4 ">
          <div class="flex flex-wrap justify-end">
            <div v-for="image in logo" :key="image" class="w-1/3 px-2">
              <!-- <img src="/images/business.png" alt="" /> -->
              <img :src="'/images/' + image + '.png'" alt="" class="mx-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: [
        "business-2",
        "business-3",
        "business-4",
      ]
    }
  },
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
@media (min-width: 1024px) {
  .bg-custom {
    background-position-x: -100px !important;
  }
}

@media (min-width: 1900px) {
  .bg-custom {
    background-position-x: -160px !important;
  }
}
</style>
