<template>
  <div>
    <WaBtn />
    <header class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-banner.jpg')">
      <TopHeader />
      <MainBanner />
    </header>

    <!-- why choose us -->
    <section class="py-10 bg-gray-1">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-white">
          Why <span class="text-yellow-1">Choose Us</span>
        </p>
        <p class="mt-4 text-sm leading-tight text-center text-white">
          Summit World Resources is a one-stop flooring solution provider in Malaysia. Our showroom is
          located at Summit USJ, Subang Jaya. We supply & install premium quality flooring solution at
          affordable prices to our customers.
        </p>

        <div class="mt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in choose_us" :key="i" class="relative w-2/3 mx-auto mb-8 lg:px-2 md:w-1/2 lg:w-1/4">
            <div class="p-6 mx-auto text-center text-white bg-white rounded-full w-60 h-60 bg-opacity-30">
              <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
              <p class="pt-5 leading-tight">
                {{ item.alt }}
              </p>
            </div>
            <div class="absolute w-24 -left-5 -top-4 md:left-8 lg:-left-5 xl:left-3">
              <img :src="'/images/' + item.number + '.png'" alt="" class="w-28" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- product  -->
    <section id="our_services" class="py-10">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-1">
          Our <span class="text-yellow-1">Flooring Solutions</span>
        </p>
        <p class="mt-4 text-sm leading-tight text-center text-gray-600">
          We are committed to provide our client with high quality and
          affordable flooring solutions. Through our continuous
          improvement and hard work, we have built ourselves a favorable
          reputation and loyal customers. We believe with the backing of our
          quality focus staff, we can take this company to a greater heights.
        </p>

        <div class="pt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in product" :key="i" class="pb-10 md:w-1/2 lg:w-1/3 md:px-1">
            <div class="relative z-0">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
              <div class="absolute left-0 right-0 w-10/12 mx-auto -bottom-5">
                <p
                  class="py-2 text-sm font-semibold leading-tight text-center capitalize transition duration-300 ease-linear lg:text-base hover:text-white text-gray-1 bg-yellow-1 hover:bg-gray-900">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- sub banner -->
    <section class="py-10 bg-gray-1">
      <div class="container">
        <div class="lg:flex lg:items-start xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 text-white lg:pr-8 lg:w-1/2">
            <div class="space-y-2 lg:text-justify">
              <p><span class="font-semibold text-yellow-1">Summit World Resources</span> -
                incorporated since 2016 with more than 15 years experience in the
                flooring industry. Our team brought over 1.5 million sqft of flooring installed. We are proud to be
                the highest rated company with over 300 5-star reviews on Google.
              </p>
              <ul class="mt-4 space-y-2 font-semibold lg:pt-[1rem]">
                <li class="flex items-start">
                  <i class="text-xl text-green-500 fa-solid fa-check"></i>
                  <p class="pl-2 text-base leading-tight">South East Asia (SEA) Business Awards</p>
                </li>
                <li class="flex items-start">
                  <i class="text-xl text-green-500 fa-solid fa-check"></i>
                  <p class="pl-2 text-base leading-tight">Most Influential Young Entrepreneur Winner (MIYE)</p>
                </li>
                <li class="flex items-start">
                  <i class="text-xl text-green-500 fa-solid fa-check"></i>
                  <p class="pl-2 text-base leading-tight"> Best Flooring Specialist by APAC</p>
                </li>
              </ul>
            </div>
            <div class="mt-6">
              <p class="w-64 py-1 text-center text-white bg-red-500 rounded-md">
                <a href="https://wa.me/60102111182">GET FREE QUOTE TODAY!</a>
              </p>
            </div>
          </div>
          <div class="mt-10 md:w-2/3 lg:mt-0 lg:w-1/2 lg:mx-0 md:mx-auto">
            <!-- <img src="/images/banner-1.jpg" alt="" class="" /> -->
            <iframe src="https://drive.google.com/file/d/1O-iBErAec4Bl6Vqvp6xnWbN2yWxj_Pn4/preview" width="100%"
              height="350" allow="autoplay"></iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- gallery -->
    <section class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900 lg:text-3xl">
          Our <span class="text-red-600">Gallery</span>
        </h1>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 lg:w-1/5 md:w-1/3">
            <img :src="'/images/' + image + '.jpg'" alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md" />
          </div>
        </div>
      </div>
    </section>

    <Testimonials />

    <!-- footer -->
    <section id="enquiry" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-yellow-1 bg-opacity-80">
            <div class="text-center">
              <p class="text-3xl font-bold text-gray-1">Send an Enquiry</p>
              <div class="bg-gray-1 w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-700">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo.png" alt="" class="w-2/3 mx-auto md:w-52" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-xl font-bold">
                Summit World Resources
              </h1>
              <p class="text-base font-normal leading-tight md:text-xl">
                F1. 113, F1.113A, First Floor Summit USJ Mall, Persiaran
                Kewajipan, Subang USJ 1. 47600 Selangor
              </p>

              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  <a href="https://wa.me/60102111182" class="">(+60) 10-211 1182</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  Mobile : <a href="tel:+60102111182">(+60) 10-211 1182</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TopHeader from "@/components/TopHeader.vue";
import WaBtn from "@/components/WaBtn.vue";
import EnquiryForm from "@/components/EnquiryForm.vue"
import MainBanner from "@/components/MainBanner.vue"
import Testimonials from "@/components/Testimonials.vue"

export default {
  name: "Home",
  components: {
    TopHeader,
    WaBtn,
    EnquiryForm,
    MainBanner,
    Testimonials
  },
  data() {
    return {
      choose_us: [
        {
          image: "choose-1",
          alt: "Our team has over 15 years of experience",
          number: "number-1",
        },
        {
          image: "choose-2",
          alt: " Installed over 1.5 million sqft of flooring",
          number: "number-2",
        },
        {
          image: "choose-3",
          alt: "300+ 5 stars reviews rated in Google/FB",
          number: "number-3",
        },
        {
          image: "choose-4",
          alt: "78% of our ID customer use our floor for their owned house project",
          number: "number-4",
        },
      ],
      product: [
        { image: "product-1", alt: "Solid Wood flooring" },
        { image: "product-2", alt: "SPC flooring" },
        { image: "product-3", alt: "Waterproof Flooring (WPL/CDF)" },
        { image: "product-4", alt: "Laminate flooring" },
        { image: "product-5", alt: "Vinyl Flooring" },
        { image: "product-6", alt: "Decking" },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
        "gallery-9",
        "gallery-10",
      ],
    };
  },
};
</script>
